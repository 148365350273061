
import imageUrlBuilder from '@sanity/image-url';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import getImageDatasFromRef from '../../queries/getImageDatasFromRef';

export default {
  props: {
    aspectRatios: {
      type: Array,
      default() {
        return [
          { sm: 'default' },
          { md: 'default' },
          { lg: 'default' },
          { xl: 'default' },
          { '2xl': 'default' }
        ];
      }
    },
    refImage: {
      type: String,
      default: () => {}
    },
    isLazyloaded: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: ''
    },
    imageObjectData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      breakpoints: [],
      src: null,
      sources: [],
      loadingAttribute: null,
      picture: {
        asset: null,
        hotspot: null,
        crop: null,
        width: null,
        height: null
      }
    };
  },

  head() {
    if (!this.picture.asset) {
      return;
    }

    return {
      link: [
        {
          rel: 'prefetch',
          as: 'image',
          href: this.src
        }
      ]
    };
  },

  computed: {
    srcCached() {
      const image = {
        asset: this.imageObjectData.metadata.asset,
        crop: this.imageObjectData.metadata.crop,
        hotspot: this.imageObjectData.metadata.hotspot
      };

      const { width } = image.asset.metadata.dimensions;
      const height = this.getAspectRatioHeight(width, 'default');

      return this.createUrl(image.asset, width, height);
    },
    cachedSources() {
      const {
        theme: { screens }
      } = resolveConfig(tailwindConfig);
      const image = {
        asset: this.imageObjectData.metadata.asset,
        crop: this.imageObjectData.metadata.crop,
        hotspot: this.imageObjectData.metadata.hotspot
      };
      const arSources = this.aspectRatios.map((ar) => {
        let height;
        const screen = Object.keys(ar)[0];
        const aspectRatio = ar[screen];
        const mediaQuery = screens[screen];
        const { width } = image.asset.metadata.dimensions;

        if (aspectRatio === 'default') {
          height = image.asset.metadata.dimensions.height;
        } else {
          height = this.getAspectRatioHeight(width, aspectRatio);
        }

        const url = this.createUrl(image.asset, width, height);
        return {
          width,
          height,
          url,
          mediaQuery,
          screen
        };
      });

      return arSources.reverse();
    }
  },

  async created() {
    if (!this.refImage) {
      console.warn('Loading with imageObjectData');
      return;
    }

    const query = getImageDatasFromRef(this.refImage);
    const docReference = await this.$sanity.fetch(query);
    const { asset, crop, hotspot } = docReference;

    this.picture.asset = { ...asset, crop, hotspot };
    this.picture.width = asset?.metadata?.dimensions?.width;
    this.picture.height = asset?.metadata?.dimensions?.height;

    this.src = this.createUrl();
    this.breakpoints = this.calculateDimensionsForEachScreen().reverse();
    this.sources = this.generateUrlPerEachBreakpoint();
    this.loadingAttribute = this.isLazyloaded ? 'lazy' : 'eager';
  },

  methods: {
    createUrl() {
      return this.createBuilder()
        .image(this.picture.asset)
        .width(this.picture.width)
        .height(this.picture.height)
        .dpr(1)
        .auto('format')
        .url();
    },
    createBuilder() {
      return imageUrlBuilder(this.$config.api);
    },
    // This function is used to define the aspect ratio of the image according
    // to the parameters we defined when calling this component.
    getAspectRatioHeight(width, ratio) {
      if (ratio === 'square') {
        return width;
      }

      if (ratio === 'panorama') {
        return Math.round((width / 147) * 36.75);
      }

      if (ratio.includes(':')) {
        const dimension = ratio.split(':');
        return Math.round((width / parseFloat(dimension[0])) * parseFloat(dimension[1]));
      }

      // if default, then we return the natural height of the image
      return this.picture.asset?.metadata?.dimensions?.height;
    },
    // calculate aspect ratio of given breakpoint
    calculateDimensionsForEachScreen() {
      const {
        theme: { screens }
      } = resolveConfig(tailwindConfig);

      const dimensions = this.aspectRatios.map((entry) => {
        const screen = Object.keys(entry);
        const aspectRatio = entry[screen];
        const mediaQuery = screens[screen];
        // const width = parseInt(mediaQuery.replace('px', ''));
        // TODO: when improving with breakpoints, refactor this part
        const width = this.picture.width;
        const height = this.getAspectRatioHeight(width, aspectRatio);

        return {
          [screen]: {
            mediaQuery,
            aspectRatio,
            width,
            height
          }
        };
      });

      return dimensions;
    },
    generateUrlPerEachBreakpoint() {
      return this.breakpoints.map((breakpoint) => {
        const screen = Object.keys(breakpoint);
        const imageData = breakpoint[screen];
        const url = this.createBuilder()
          .image(this.picture.asset)
          .width(imageData.width)
          .height(imageData.height)
          .auto('format')
          .dpr(1)
          .fit('crop')
          .url();

        return {
          screen: screen[0],
          url,
          ...imageData
        };
      });
    }
  }
};
