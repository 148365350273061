const maxWidth = '1900px';
module.exports = {
  mode: 'jit',
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1793px'
    },
    fontFamily: {
      // Add fonts
      sans: ['Galano', 'sans-serif']
    },
    grid: {
      gridXMarginDesktop: '5vw',
      gridXMarginMobile: '24px',
      gutterXDesktop: '48px',
      gutterXMobile: '16px',
      gutterYDesktop: '48px',
      gutterYMobile: '30px'
    },
    spacing: {
      /* These values are to be used for vertical spacing mainly
       By default, these values are inherited by the:
       padding, margin, width, height, maxHeight, gap, inset, space, and translate core plugins. */

      0: '0', // 0px
      1: '0.5rem', // 8px
      2: '1rem', // 16px
      3: '1.5rem', // 24px
      4: '2rem', // 32px
      5: '2.5rem', // 40px
      6: '3rem', // 48px
      7: '3.5rem', // 56px
      8: '4rem', // 64px
      9: '5rem', // 80px
      10: '6rem', // 96px
      11: '7rem' // 112px
    },
    extend: {
      maxWidth: (theme) => {
        return {
          content: maxWidth,
          ...theme('spacing')
        };
      },
      animation: {
        'spin-slow': 'spin 8s linear infinite'
      },
      colors: {
        palette: {
          // Add project colors
          yellow: '#FFFF00',
          green: '#00FFBC',
          yellowHover: '#F4F400',
          greenHover: '#00FAB8'
        }
      }
    }
  },
  variants: {
    extend: {}
  },
  plugins: [
    require('tailwindcss-selection-variant'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography')
  ],
  corePlugins: {
    container: false
  }
};
